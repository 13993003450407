import React from 'react';

import { Suppliers as SuppliersView } from '@silvertours/common-landingpages-view';
import { LayoutLegacy, useSiteInfo } from '@silvertours/front-legacy-entities';

import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import {
  PrimaryTitle,
  SupplierList,
  SupplierListItem,
  SupplierWrapper,
  StyledThirdPartyLogo,
} from './Suppliers.styles';
import { TrustSeals } from '../TrustSeals';

type SuppliersProps = {
  title?: string;
  suppliers?: SuppliersView;
  includeSearch?: boolean;
  includeTrustSeals?: boolean;
};

const Suppliers = ({
  title,
  suppliers,
  includeSearch = true,
  includeTrustSeals = true,
}: SuppliersProps) => {
  const {
    settings: { featureToggles },
  } = useSiteInfo();

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('sm')}px)`,
    true,
  );

  // If new search design used, do not render h1 in Suppliers
  const useNewSearch =
    (featureToggles.useNewDesignSearchForm ||
      featureToggles.useNewDesignSearchFormVersion2) &&
    isMobile;

  if (!title && (!suppliers || suppliers.length === 0)) {
    return null;
  }

  return (
    <LayoutLegacy.FilledSegment id="suppliers">
      {includeTrustSeals && <TrustSeals />}
      <SupplierWrapper includeSearch={includeSearch}>
        {!useNewSearch && title && <PrimaryTitle>{title}</PrimaryTitle>}
        {suppliers && includeSearch && (
          <SupplierList isStandAlone={!title}>
            {suppliers.map(({ id }) => (
              <SupplierListItem key={id}>
                <StyledThirdPartyLogo name={id} withPadding="none" grayScale />
              </SupplierListItem>
            ))}
          </SupplierList>
        )}
      </SupplierWrapper>
    </LayoutLegacy.FilledSegment>
  );
};

export { Suppliers };
export type { SuppliersProps };
